import React, { useContext } from "react";
import cx from "classnames";
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledButtonDropdown } from "reactstrap";
import { useSelector } from "react-redux";
import { pluralize } from '../../utils/pluralize';

import { CSSTransition, TransitionGroup } from "react-transition-group";

import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import LocationControl from "../../components/LocationControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCircleExclamation, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../context/AccountContext";
import { LocationContext } from "../../context/LocationContext";
import HelpVideo from "./Components/HelpVideo";

const Header = ({ showDropdowns }) => {
  const navigate = useNavigate();
  const { proLocationCount } = useContext(LocationContext);
  const { paddleSubscriptions, account } = useContext(AccountContext);
  // Access Redux state using useSelector
  const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = useSelector(
    (state) => ({
      headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
      enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
      enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    })
  );

  //console.log("in header",showDropdowns);

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          })}
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <div>
            <HeaderLogo />
            <div
              className={cx("app-header__content", {
                "header-mobile-open": enableMobileMenuSmall,
              })}
            >
              <div className="app-header-left">
                <Nav className="header-megamenu">
                  {showDropdowns &&
                    <LocationControl />
                  }
                </Nav>
              </div>

              {!account?.is_pos_connected && proLocationCount > 0 && (
                <div className="app-header-left" style={{paddingLeft: '10px'}}>
                  <FontAwesomeIcon icon={faCircleExclamation} className="text-danger ms-0 me-2" />
                  <a onClick={() => {navigate("/account/connect")}} style={{cursor:'pointer', color:'#000'}}>
                        You are not connected to Square!
                  </a>
                </div>
              )}
              {paddleSubscriptions?.hasPastDue ? (
                <div className="app-header-left" style={{paddingLeft: '10px'}}>
                  <FontAwesomeIcon icon={faCircleExclamation} className="text-danger ms-0 me-2" />
                  <a onClick={() => {navigate("/account/locations")}} style={{cursor:'pointer', color:'#000'}}>
                        Past Due Subscription!
                  </a>
                </div>
              ) : (proLocationCount < paddleSubscriptions?.[0]?.quantity) ? (
                <div className="app-header-left" style={{paddingLeft: '10px'}}>
                  <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning ms-0 me-2" />
                  <a onClick={() => {navigate("/account/locations")}} style={{cursor:'pointer', color:'#000'}}>
                        Unused {pluralize(paddleSubscriptions?.[0]?.quantity - proLocationCount,'Subscription')}
                  </a>
                </div>
              ) : (proLocationCount > paddleSubscriptions?.[0]?.quantity) && (
                <div className="app-header-left" style={{paddingLeft: '10px'}}>
                  <FontAwesomeIcon icon={faCircleExclamation} className="text-danger ms-0 me-2" />
                  <a onClick={() => {navigate("/account/locations")}} style={{cursor:'pointer', color:'#000', fontWeight:'bold', borderBottom:'1px solid'}}>
                        Subscription Issue
                  </a>
                </div>
              )}
              <div className="app-header-right">
                <HelpVideo />
                <UserBox showDropdowns={showDropdowns} />
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default Header;
