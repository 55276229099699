import React, { useState, useEffect, useContext, useRef, lazy, Suspense } from 'react';
import { Button, Progress, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Step from './step';

import { AccountContext } from '../../context/AccountContext';

import supabase from '../../lib/supabase';

import './quickstart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCirclePlay, faCircleXmark, faDownLeftAndUpRightToCenter, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { LocationContext } from '../../context/LocationContext';

const LazyReactPlayer = lazy(() => import("react-player"));

const QuickstartPanel = () => {
  const [showQuickstart, setShowQuickstart] = useState(true); // Initial state
  const [openStepId, setOpenStepId] = useState(null);
  const [steps, setSteps] = useState([]);
  const [nextSteps, setNextSteps] = useState([]);
  const [useNextSteps, setUseNextSteps] = useState(false);
  const [completedStepsCount, setCompletedStepsCount] = useState(0);
  const [completedPct, setCompletedPct] = useState(0);
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalVideos, setModalVideos] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing

  const { account } = useContext(AccountContext);
  const { maxSubscriptionLevel } = useContext(LocationContext);

  // Load the state from localStorage on component mount
  useEffect(() => {
    const savedShowQuickstart = localStorage.getItem('showQuickstart');
    if (savedShowQuickstart !== null) {
      //console.log("showquickstart",JSON.parse(savedShowQuickstart))
      setShowQuickstart(JSON.parse(savedShowQuickstart));
    }
  }, []);

  // Save the state to localStorage whenever it changes
  useEffect(() => {
    //console.log("Saving quickstart to local",showQuickstart);
    localStorage.setItem('showQuickstart', JSON.stringify(showQuickstart));
  }, [showQuickstart]);

  useEffect(() => {
    if (completedPct === 100) {
      setUseNextSteps(true); // Update state to trigger re-render
    }
  }, [completedPct]);

  useEffect(() => {
    const fetchData = async () => {
      if (!account?.user_id) return;

      const { data, error } = await supabase
        .from('quickstart_counts')
        .select('*')
        .eq('user_id', account.user_id);

      if (error) {
        console.error(error);
        throw error;
      }
      if (Array.isArray(data)) {
        setData(data[0]);
      } else {
        setData([])
      }
    };

    fetchData();
  }, [account?.user_id]);

  useEffect(() => {
    setSteps([
      { id: 1, title: 'Create a Location', icon: 'check', content: <><p>Create a location in Boost for each of your physical locations</p><p>Click your name at the top right, then click Locations</p></>, complete: data?.locations > 0},
      { id: 2, title: 'Connect your POS', icon: 'check', content: <><p>Connect to Square to synchronize sales data</p><p>Click your name at the top right, then click Integrations</p></>, complete: account?.is_pos_connected, minSubscriptionLevel: 2 },
      { id: 3, title: 'Add Ingredients', icon: 'check', content: <><p>Add your ingredients to Boost</p><p>Click Ingredients in the left hand menu, then click Ingredients</p></>, complete: data?.ingredients > 0,
        videos: [
          { title: "Overview", url: "Ingredients Overview.mp4" },
          { title: "New Ingredient", url: "New Ingredient.mp4" },
          { title: "New Source", url: "New Source.mp4" },
        ]
      },
      { id: 4, title: 'Link Ingredients to Modifiers', icon: 'check', content: <><p>Link ingredients to Modifiers</p><p>Click Ingredients in the left hand menu, then click Modifiers</p></>, complete: data?.linked_modifiers > 0, minSubscriptionLevel: 2,
        videos: [
          { title: "Overview", url: "Modifiers.mp4"}
        ]
       },
      { id: 5, title: 'Add Your Recipes', icon: 'check', content: <><p>Add your recipes, enabling automatic COGS calculation for you and digital recipes for your team</p><p>After integrating with Square, click Recipes in the left hand menu, then click Recipes</p></>, complete: data?.recipes > 0, disabled: !account?.is_pos_connected, minSubscriptionLevel: 2,
        videos: [
          { title:'Overview', url:'Recipe Overview.mp4' },
          { title:'Add/Remove Ingredient', url:'Recipe Add-Remove Ingredient.mp4' },
          { title:'Replaceable Modifiers', url:'Replaceable Modifiers.mp4' },
          { title:'Prep Recipes', url:'Prep Recipes Overview.mp4' },
        ]
      },
      { id: 6, title: 'Add Inventory Zones', icon: 'check', content: <><p>Organize your ingredients into zones to make inventory counts go fast</p><p>After adding ingredients, click Inventory Zones in the left hand menu</p></>, complete: data?.inventory_zones > 0,
        videos: [
          { title: "Overview", url: "Inventory Zones.mp4"}
        ] },
      { id: 7, title: 'Your First Inventory Count', icon: 'check', content: <><p>Your first inventory count in Boost! Let's set up the system with what you have on hand right now.</p><p>After adding ingredients and setting up your inventory zones, click Count in the left hand menu, then click Full Count</p></>, complete: data?.inventory_ledger_counts > 0,
        videos: [
          { title: "Overview", url: "Full Count.mp4"}
        ] }
    ].filter(step => step.minSubscriptionLevel === undefined || maxSubscriptionLevel >= step.minSubscriptionLevel));

    setNextSteps([
      { id: 101, title: 'Prep Recipes', content: <><p>Some items require a step in the middle before it's ready for sale as an item on your POS. That's where Prep Recipes come in</p><p>Add an ingredient source and click "Make It" in the wizard, then after adding it, click the <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> icon</p></>, complete: false, disabled: !account?.is_pos_connected, minSubscriptionLevel: 2,
        videos: [
          { title: "Overview", url: "Prep Recipes Overview.mp4"}
        ] },
      { id: 102, title: 'Ordering Suggestions', content: <><p>Once you've added all of your inventory and set up Inventory Zones with the right items inside, you can use the Create New Order screen to get an idea of what you need (based on your PARs) and also tell Boost what you bought and when you received it in order to keep your inventory as up to date as possible</p><p>Click Ordering in the left hand menu, then Create New Order</p></>, complete: false, disabled: !account?.is_pos_connected,
        videos: [
          { title: "Create New Order", url: "Create New Order.mp4"},
          { title: "Receive Order", url: "Receive Order.mp4"}
        ] },
      { id: 103, title: 'Checklists', content: <><p>Digital checklists give your team an understanding of what you want done, and give you (and them) a way to track it easily</p><p>Click Checklists in the left hand menu</p></>, complete: false, disabled: !account?.is_pos_connected, minSubscriptionLevel: 2,
        videos: [
          { title: "Overview", url: "Checklists.mp4"}
        ] },
      { id: 104, title: 'Log in on a Tablet', content: <><p>Now it's time to log into a tablet and experience the power your team will have at their fingertips</p><p>To find out how, click Tablets in the left hand menu, then Tablet Info</p></>, complete: false, disabled: !account?.is_pos_connected, minSubscriptionLevel: 2 },
      { id: 105, title: 'Go Pro for so much more!', content: <><p>You're on the free version right now, but you can easily upgrade to Pro for more power!</p><p>The Pro subscription includes Square integration, automated inventory based on sales, Recipes with COGS calculation, unlimited KDS access, and more - all for just $149/mo per location (plus tax)</p><p>We have a no questions asked 30 day satisfaction guarantee, so you have nothing to lose and so much to gain!</p><p><a href="/account/locations">Click Here</a> to go Pro!</p></>, complete: false, disabled: !account?.is_pos_connected, maxSubscriptionLevel: 1 }
    ].filter(step => (step.minSubscriptionLevel === undefined || maxSubscriptionLevel >= step?.minSubscriptionLevel) && (step.maxSubscriptionLevel === undefined || maxSubscriptionLevel <= step.maxSubscriptionLevel)));
  }, [account, data, maxSubscriptionLevel]);

  useEffect(() => {
    setCompletedStepsCount(steps.filter(step => step.complete).length);
    setCompletedPct((steps.filter(step => step.complete).length) / steps.length * 100);
  }, [steps]);

  const toggleAccordion = (id) => {
    setOpenStepId(openStepId === id ? null : id);
  };

  const openVideoModal = async (videos) => {
    const preloadedVideos = await Promise.all(
      videos.map(async (video) => {
        const module = await import(`../../assets/videos/${video.url}`);
        return { ...video, url: module.default };
      })
    );
    setModalVideos(preloadedVideos);
    setShowModal(true);
  };

  const handlePlayerPlay = (index) => {
    setPlayingIndex(index); // Set the current video as playing
  };
  
  const handlePlayerPause = () => {
    setPlayingIndex(null); // Reset playing state
  };

  const closeQuickstart = async () => {
    // Update the DB & the account context

    const { error } = await supabase
    .from('userinfo')
    .update({display_quickstart:false});

    if (error) {
      throw error;
    }
    
    setShowQuickstart('remove')
  };

  const toggleQuickstart = () => {
    setShowQuickstart(!showQuickstart);
  };

  if (!account?.display_quickstart || showQuickstart === 'remove') return null;
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {showQuickstart ? (
        <div className="quickstart sidebar-shadow">
          <div className="app-sidebar-bg opacity-06"></div>
          <div className="header">
            <div className='title'>
              {useNextSteps ? 'Next Steps' : 'Quickstart'}
            </div>
          </div>
          {!useNextSteps ? (
            <div className='completion-bar progress-section'>
              <Progress className="progress-bar-rounded bar-blue" style={{ width: '90%', margin: 'auto', marginTop: '15px' }} value={completedPct} />
              <div style={{ width: '100%', marginTop: '7px', textAlign: 'center' }}>{completedStepsCount} of {steps.length} Completed</div>
            </div>
          ) : (
            <>
              <div className='completion-bar-success-message progress-section'>
                Congratulations on completing the Quickstart!
              </div>
              <div className='next-steps-message'>
                <p>Now you know how to do everything you need to do to get the right data into Boost!</p>
                <p>But we're not done there - Here are some other features you haven't been introduced to yet</p>
              </div>
            </>
          )}
          <div className="steps">
            {(useNextSteps ? nextSteps : steps).map(step => (
              <Step
                key={step.id}
                title={step.title}
                icon={step.icon}
                content={step.content}
                complete={step.complete}
                videos={step.videos}
                disabled={step.disabled}
                isOpen={openStepId === step.id}
                toggleAccordion={() => toggleAccordion(step.id)}
                openVideoModal={openVideoModal}
              />
            ))}
          </div>
          <div className="close-buttons">
            <div className="close" onClick={closeQuickstart}>
              <Button>
                <FontAwesomeIcon icon={faCircleXmark} />&nbsp;&nbsp;Close
              </Button>
            </div>
            <div className="shrink" onClick={toggleQuickstart}>
              <Button>
                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />&nbsp;&nbsp;Hide
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="quickstartButton" onClick={toggleQuickstart}>
          <FontAwesomeIcon icon={faCirclePlay} />&nbsp;&nbsp;Show Quickstart
        </div>
      )}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered className="custom-modal">
        <ModalHeader toggle={() => setShowModal(false)}>Quickstart Videos</ModalHeader>
        <ModalBody className="scrollable-modal-body">
          {modalVideos.map((video, index) => (
            <div key={index} className="video-row">
              <h3>{video.title}</h3>
              <Suspense fallback={<div>Loading...</div>}>
                <LazyReactPlayer
                  url={video.url}
                  controls
                  width="100%"
                  height="360px"
                  playing={playingIndex === index} // Play only if the current index matches
                  onPlay={() => handlePlayerPlay(index)}
                  onPause={handlePlayerPause}
                  config={{
                      file: {
                          attributes: {
                              controlsList: "nodownload",
                          },
                      },
                  }}
                />
              </Suspense>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default QuickstartPanel;
