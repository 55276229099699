export const videoData = {
	'/ingredients/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Ingredients Overview.mp4'
			},
			{
				title:'New Ingredient',
				url:'New Ingredient.mp4'
			},
			{
				title:'New Source',
				url:'New Source.mp4'
			},
		]
	},
	'/ingredients/modifiers/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Modifiers.mp4'
			},
		]
	},
	'/ingredients/recipes/detail/':
	{
		help_videos: [
			{
				title:'Prep Recipes',
				url:'Prep Recipes Overview.mp4'
			},
			{
				title:'Add/Remove Ingredient',
				url:'Recipe Add-Remove Ingredient.mp4'
			},
			{
				title:'Replaceable Modifiers',
				url:'Replaceable Modifiers.mp4'
			},
			{
				title:'Recipes Overview',
				url:'Recipe Overview.mp4'
			},
		]
	},
	'/ingredients/recipes/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Recipe Overview.mp4'
			},
			{
				title:'Add/Remove Ingredient',
				url:'Recipe Add-Remove Ingredient.mp4'
			},
			{
				title:'Replaceable Modifiers',
				url:'Replaceable Modifiers.mp4'
			},
			{
				title:'Prep Recipes',
				url:'Prep Recipes Overview.mp4'
			},
		]
	},
	'/ingredients/prep_categories/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Prep Categories.mp4'
			},
		]
	},
	'/inventory/full_count/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Full Count.mp4'
			},
		]
	},
	'/inventory/limited_count/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Limited Count.mp4'
			},
		]
	},
	'/inventory/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Inventory Ledger.mp4'
			},
		]
	},
	'/inventory/inventory_zones/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Inventory Zones.mp4'
			},
		]
	},
	'/inventory/new_order/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Create New Order.mp4'
			},
		]
	},
	'/inventory/receive_order/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Receive Order.mp4'
			},
		]
	},
	'/checklists/':
	{
		help_videos: [
			{
				title:'Overview',
				url:'Checklists.mp4'
			},
		]
	},
};