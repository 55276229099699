// src/components/LocationControl.js
import React, { useContext } from 'react';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LocationContext, UpdateLocationContext } from '../context/LocationContext';

const LocationControl = () => {
  const { activeLocation, locations, isSyncing } = useContext(LocationContext);
  const { setActiveLocation } = useContext(UpdateLocationContext);

  const handleSelectLocation = (location) => {
    console.log('setting active location',activeLocation);
    setActiveLocation(location);
  };

  const currentLocation = locations.find(location => location.id === activeLocation.id);

  //console.log(currentLocation);

  return (
    <UncontrolledButtonDropdown nav>
      <DropdownToggle nav>
      {isSyncing ? (
        <><i className="fas fa-spinner fa-spin"></i>&nbsp;</>
      ) : (
        <i className="nav-link-icon pe-7s-map-marker"></i>
      )} {currentLocation ? currentLocation.display_name : 'Select Location'}
        <FontAwesomeIcon className="ms-2 opacity-5" icon={faAngleDown} />
      </DropdownToggle>
      <DropdownMenu>
        {locations.length > 0 ? (locations.map(location => (
          <DropdownItem key={location.id} onClick={() => { 
            console.log("Test"); 
            handleSelectLocation(location);
          }}>
            {location.display_name}<br/>
			      ({location.address_line1})
          </DropdownItem>
        ))) : (
          <DropdownItem key="newlocation"
            onClick={() => {
              window.location.href = `/account/locations/`
            }}
          >
            Create Your First Location...
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default LocationControl;
