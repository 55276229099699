import React, { Fragment, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  DashboardsNav,
  InventoryNav,
  MenuNav,
  RecipesNav,
  ChecklistNav,
  TabletNav
} from "./NavItems";
import { LocationContext } from "../../context/LocationContext";

const Nav = (props) => {
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);
  const dispatch = useDispatch();

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  //const { account } = useContext(AccountContext);
  const { activeLocation } = useContext(LocationContext);
  //console.log(activeLocation.subscription_level);
  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Back Office</h5>
      <MetisMenu content={DashboardsNav(activeLocation.subscription_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
      {(InventoryNav(activeLocation.subscription_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Inventory</h5>
        <MetisMenu content={InventoryNav(activeLocation.subscription_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      <h5 className="app-sidebar__heading">Recipes & Ingredients</h5>
      <MetisMenu content={RecipesNav(activeLocation.subscription_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
      {(ChecklistNav(activeLocation.subscription_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Checklists</h5>
        <MetisMenu content={ChecklistNav(activeLocation.subscription_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      {(TabletNav(activeLocation.subscription_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">On Location</h5>
        <MetisMenu content={TabletNav(activeLocation.subscription_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )}
      {/*(MenuNav(activeLocation.subscription_level).length > 0 && 
        <>
        <h5 className="app-sidebar__heading">Menus</h5>
        <MetisMenu content={MenuNav(activeLocation.subscription_level)} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" classNameStateIcon="fa fa-chevron-down"/>
        </>
      )*/}
    </Fragment>
  );
};

export default Nav;
