import React, { useState, useEffect, lazy, Suspense } from "react";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

import "./helpvideo.css";

const LazyReactPlayer = lazy(() => import("react-player"));

const HelpVideo = () => {
    const [showModal, setShowModal] = useState(false);
    const [videos, setVideos] = useState([]);
    const [videoExists, setVideoExists] = useState(false);
    const [videoUrls, setVideoUrls] = useState({});
    const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing
    const location = useLocation();

    useEffect(() => {
        const route = location.pathname.replace(/\/prep-\d+/, "");
        const { videoData } = require(`./VideoData`);
        //console.log(route,videoData);
        const routeVideos = videoData?.[route]?.help_videos || [];
        //console.log(routeVideos);

        if (routeVideos.length > 0) {
            const preloadVideos = async () => {
                const urls = {};
                for (const video of routeVideos) {
                    //,video);
                    const module = await import(`../../../../assets/videos/${video.url}`);
                    urls[video.url] = module.default;
                }
                setVideoUrls(urls);
            };
            //console.log(preloadVideos);

            setVideos(routeVideos);
            setVideoExists(true);
            preloadVideos();
        } else {
            setVideos([]);
            setVideoExists(false);
        }
    }, [location.pathname]);

    const handleClose = () => {
        setShowModal(false);
    };

    const handlePlayerPlay = (index) => {
        setPlayingIndex(index); // Set the current video as playing
    };
    
    const handlePlayerPause = () => {
        setPlayingIndex(null); // Reset playing state
    };

    return (
        <div className="pe-0" style={{ paddingLeft: "5px", fontSize: "1.25rem" }}>
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    {videoExists && (
                        <FontAwesomeIcon
                            icon={faCircleQuestion}
                            onClick={() => setShowModal(true)}
                            style={{ cursor: "pointer" }}
                        />
                    )}
                </div>
            </div>

            <Modal isOpen={showModal} toggle={handleClose} centered className="custom-modal">
                <ModalHeader toggle={handleClose}>Help Videos</ModalHeader>
                <ModalBody className="scrollable-modal-body">
                    <div className="video-list-container">
                        {videos.map((video, index) => (
                            <div key={index} className="video-row">
                                <h3>{video.title}</h3>
                                {videoUrls[video.url] ? (
                                    <Suspense fallback={<div>Loading video...</div>}>
                                        <LazyReactPlayer
                                            url={videoUrls[video.url]}
                                            controls
                                            playing={playingIndex === index} // Play only if the current index matches
                                            onPlay={() => handlePlayerPlay(index)}
                                            onPause={handlePlayerPause}
                                            width="100%"
                                            height="360px"
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: "nodownload",
                                                    },
                                                },
                                            }}
                                        />
                                    </Suspense>
                                ) : (
                                    <div>Loading video...</div>
                                )}
                            </div>
                        ))}
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default HelpVideo;
