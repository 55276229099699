import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_API_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseKey,{
	auth: {
	  persistSession: true,  // Keeps the session persistent across reloads
	  autoRefreshToken: true, // Automatically refresh tokens when needed
	},
  });

export default supabase;

export const fetchAllPages = async (query, pageSize = 1000) => {
	let allData = [];
	let start = 0;
	let end = pageSize - 1;
	let moreData = true;
	let error = null;
  
	while (moreData) {
	  const { data, error: pageError } = await query.range(start, end);
  
	  if (pageError) {
		error = pageError;
		break;
	  }
  
	  allData = allData.concat(data);
  
	  // If fewer rows than pageSize are returned, we're done
	  if (data.length < pageSize) {
		moreData = false;
	  } else {
		start += pageSize;
		end += pageSize;
	  }
	}
  
	return { data: allData, error };
  };
  