//###TODO: This needs to be updated to handle only Magic Links better than having to 
// manually exclude every URL that might trigger it accidentally. 
// Maybe something from here? https://supabase.com/docs/reference/javascript/auth-resetpasswordforemail

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import supabase from '../lib/supabase';

const MagicLinkHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const processMagicLink = async () => {
      let hash = window.location.hash.substring(1);
      if (hash.startsWith('/')) {
        hash = hash.substring(1);
      }

      const params = new URLSearchParams(hash);
      const error = params.get("error");
      const errorCode = params.get("error_code");
      const errorDescription = params.get("error_desc");

      // Handle expired magic link errors
      if (
        error === "access_denied" &&
        errorCode === "403" &&
        errorDescription === "Email link is invalid or has expired"
      ) {
        console.log("Magic link expired! Redirecting...");
        navigate("/expired", { replace: true });
        return;
      }

      //No error, process the new session
      const type = params.get('type');
      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');

      // Handle valid magic links
      if (access_token && refresh_token) {
        const { error: sessionError } = await supabase.auth.setSession({ access_token, refresh_token });

        if (sessionError) {
          console.error('Error setting session:', sessionError.message);
          return;
        }

        //This can probably be better managed with internal supabase logic (see https://supabase.com/docs/reference/javascript/auth-resetpasswordforemail)
        if (type === 'recovery') {
          sessionStorage.setItem('passwordRecoveryFlow', 'true');
          navigate('/admin/new_password');
        } else if (type === 'signup') {
          navigate('/registration/locations');
        } else {
          navigate('/');
        }
      }
    };

    //if (window.location.pathname.includes("/square-oauth-redirect")) {
      processMagicLink();
    //}

  }, [navigate]);

  return null; // This component does not render anything
};

export default MagicLinkHandler;