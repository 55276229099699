import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RenameErrorDescription = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("error_description")) {
      const errorValue = params.get("error_description");

      // Remove old param
      params.delete("error_description");

      // Add new param with a different name
      params.set("error_desc", errorValue);

      // Update the URL without reloading the page
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [location, navigate]);

  return null;
};

export default RenameErrorDescription;
